import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var sections = document.querySelectorAll('section.GalleryContent');

		if( sections.length > 0 ) {

			function slickGallerySections( section ) {

				var carousel = section.querySelector('.carousel');
				var buttonNext = section.querySelector('button.control__button--next');
				var buttonPrev = section.querySelector('button.control__button--prev');

				$(carousel).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2000,
				});

				buttonNext.addEventListener('click', function() {

					$(carousel).slick('slickNext');

				});

				buttonPrev.addEventListener('click', function() {

					$(carousel).slick('slickPrev');

				});

			}

			sections.forEach( function( section, index ) {

				if( section.querySelectorAll('.carousel .image-wrapper').length > 1 ) {

					new slickGallerySections(section);

				}

			});

		}

	},
	finalize() {

	}

}