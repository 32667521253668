import GalleryContent from '../../Sections/GalleryContent/GalleryContent';

export default {

	init() {

		var container = document.querySelector('main.Location');

		if( container ) {

			GalleryContent.init();

		}

	},
	finalize() {

	}

}