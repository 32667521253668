import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {

	init() {

		var container = document.querySelector('main.Gallery');

		if( container ) {

			// -- photoswipe -- //

				var gallery = container.querySelector('.section__grid');

				var images = gallery.querySelectorAll('.grid__image');
				var pswp = document.querySelectorAll('.pswp')[0]; // photoSwipe mask
				var items; // photoSwipe images
				var options = Array(); // photoSwipe options
					options.fullscreenEl = false;
					options.shareEl = false;
				var photoSwipe; // photoSwipe object

				function Init(index) {

					items = Array();

					options.index = parseInt(index, 10);

					var item; 
					var image;

					images.forEach( function( image , index2 ) {

						item = {
							src: image.getAttribute('data-url'),
		                    w: image.getAttribute('data-width'),
		                    h: image.getAttribute('data-height'),
						};

						items.push( item );


					});

					photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);

				}

				Init();

				images.forEach( function( image,index ) {

					image.addEventListener('click', function() {

						Init( index );
						photoSwipe.init();	

					});	

				});

				

			// -- END -- //


		}

	},
	finalize() {

	}

}