import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('section.OfferSlider');

		if( container ) {


			var carousel = container.querySelector('.carousel');

			var slides = carousel.querySelectorAll('.SpecialTile').length;
			var currentSlide = 0;
			var slidesToShow = 3;
			var interval;

			function clearLoop() {

				clearInterval( interval );
				interval = null;

			}

			function startLoop() {

				interval = setInterval(
					function() {

						if( currentSlide < (slides - slidesToShow) ) {

							$(carousel).slick('slickGoTo', currentSlide+1);

						} else { 

							$(carousel).slick('slickGoTo', 0);

						}

						currentSlide = $(carousel).slick('slickCurrentSlide');

					},	
					2000
				);

			}

			carousel.addEventListener('mouseenter', function() {

				clearLoop();

			});

			carousel.addEventListener('mouseleave', function() {

				startLoop();

			});

			window.addEventListener('resize', function() {

				clearLoop();

				slidesToShow = 3;

				if( window.innerWidth < 1542.94737 ) {

					slidesToShow = 3.5;

				} else if( window.innerWidth < 1021 ) {

					slidesToShow = 2.5;

				} else if( window.innerWidth < 621 ) {

					slidesToShow = 1.2;

				}

				startLoop();

			});

			$(carousel).slick({
				arrows: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				rows: false,
				infinite: false,
				responsive: [
					{
						breakpoint: 1542,
						settings: {
							slidesToShow: 3.5
						}
					},
					{
						breakpoint: 1021,
						settings: {
							slidesToShow: 2.2,
						}
					},
					{
						breakpoint: 621,
						settings: {
							slidesToShow: 1.2,
						}
					}
				]
			});	

			$(carousel).on('afterChange', function( event, slick, currentSlideSlick) {

				currentSlide = currentSlideSlick;

			});

			startLoop();

		}

	}, 
	finalize() {

	}

}