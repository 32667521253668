export default {

	init(){

		var container = document.querySelector('section.YoutubeSection');

		if( container )  {

			var placeholder = container.querySelector('.video__placeholder');
			var frame = container.querySelector('iframe');

			placeholder.addEventListener('click', function() {

				frame.setAttribute('src', frame.getAttribute('data-src') );
				placeholder.classList.remove('active');
				frame.classList.add('active');

			});

		}

	},
	finalize() {

	}

}