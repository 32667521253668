import Cookie from '../Components/Cookie/Cookie';

export default {

	init() {

		Cookie.init();

		// -- MobileMenu -- //

			var body = document.body;

			var hamburger = document.querySelector('.hamburger');

			var mobileMenu = document.querySelector('section.MobileMenu');
			var mobileMenuContent = mobileMenu.querySelector('.MobileMenu__content');

			hamburger.addEventListener('click', function() {

				if( mobileMenu.classList.contains('active') ) {

					mobileMenu.classList.remove('active');
					hamburger.classList.remove('is-active');
					body.classList.remove('disableScroll');

				} else {

					mobileMenu.classList.add('active');
					hamburger.classList.add('is-active');
					body.classList.add('disableScroll');

				}

			});

			mobileMenu.addEventListener('click', function() {

				mobileMenu.classList.remove('active');
				hamburger.classList.remove('is-active');
				body.classList.remove('disableScroll');

			});

			mobileMenuContent.addEventListener('click', function(e) {

				e.stopPropagation();
				
			});

		// -- END -- //


	},
	finalize() {

	}

}